// hook.js
import { useQuery } from "@tanstack/react-query";

import {
  fetchTargets,
  fetchTargetCount,
  fetchSearchData,
  fetchSearches,
  fetchDomainStageMap,
  fetchActors,
  fetchContacts,
} from "@/services/api";

export function useSearches() {
  return useQuery({
    queryKey: ["searches"],
    queryFn: fetchSearches,
  });
}

export function useSearchData({ uid }) {
  return useQuery({
    queryKey: ["searchData", Number(uid)],
    queryFn: () => fetchSearchData({ uid }),
    enabled: !!uid,
  });
}

export function useTargets({ searchUid, stage }) {
  return useQuery({
    queryKey: ["targets", Number(searchUid), stage],
    queryFn: () => fetchTargets({ searchUid, stage }),
    refetchInterval: 60 * 1000,
    enabled: !!searchUid && !!stage,
  });
}

export function useTargetCount({ searchUid }) {
  return useQuery({
    queryKey: ["targetCount", Number(searchUid)],
    queryFn: () => fetchTargetCount({ searchUid }),
    refetchInterval: 60 * 1000,
    enabled: !!searchUid,
  });
}

export function useDomainStageMap({ searchUid }) {
  return useQuery({
    queryKey: ["domainStageMap", Number(searchUid)],
    queryFn: () => fetchDomainStageMap(searchUid), // Pass just the ID
    enabled: !!searchUid,
  });
}

export function useActors() {
  return useQuery({
    queryKey: ["actors"],
    queryFn: fetchActors,
  });
}

export function useContacts() {
  return useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContacts,
  });
}
