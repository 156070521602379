import { Box, LinearProgress, Rating, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function TargetsSummary({ targets, currentView, searchData }) {
  const [ratingSummary, setRatingSummary] = useState({ 5: 0, 4: 0, 3: 0 });
  const avgRating =
    targets.reduce((acc, target) => acc + target.rating, 0) / targets.length;

  useEffect(() => {
    if (Array.isArray(targets)) {
      const counts = { 5: 0, 4: 0, 3: 0 };
      targets.forEach((target) => {
        if (target.rating >= 3 && target.rating <= 5) {
          counts[target.rating]++;
        }
      });
      setRatingSummary(counts);
    }
  }, [targets]);

  if (!["validate", "outbox", "send"].includes(currentView)) {
    return <></>;
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", pt: 2, gap: 2, pr: 10 }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body">{avgRating.toFixed(1)}</Typography>

        <Rating
          value={parseFloat(avgRating.toFixed(1))}
          readOnly
          size="small"
          sx={{ mx: 1 }}
        />

        <Typography variant="caption">({targets.length})</Typography>
      </Box>
      {[5, 4, 3].map((value) => (
        <Box key={value} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            {value}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(ratingSummary[value] / targets.length) * 100}
            sx={{ width: "100px", mx: 1 }}
          />
          <Typography variant="caption">
            ({ratingSummary[value] || 0})
          </Typography>
          <Box sx={{ width: "80%", ml: 2 }}>
            <Typography variant="caption">
              {searchData?.meta[`${value}`]}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
