import { LinkedIn } from "@mui/icons-material";
import { Box, Chip, IconButton, Link } from "@mui/material";

import EditTextarea from "@/components/EditTextArea";
import RatingContainer from "@/components/RatingContainer";
import CommentsPopper from "@/components/CommentsPopper";
import { fromNow } from "@/utils/time";
import { FIT_OPTIONS } from "@/fragments/Constants";

const calculateFocusScore = (row) => {
  let score = 0;

  // Iterate through all properties of the row
  Object.keys(row).forEach((key) => {
    // Check if the key contains "focus" (case insensitive)
    if (/focus/i.test(key)) {
      // Add the value to the score if it's a number
      const value = row[key];
      if (typeof value === "number") {
        score += value;
      } else if (typeof value === "string" && !isNaN(value)) {
        // Handle values that might be numbers stored as strings
        score += parseFloat(value);
      }
    }
  });

  return score;
};

export const standardColumns = (searchData) => [
  {
    field: "fit",
    headerName: "✨ fit",
    width: 120,
    type: "singleSelect",
    valueOptions: FIT_OPTIONS,
  },
  {
    field: "fit_reason",
    headerName: "✨ reason",
  },
  {
    field: "fit_score",
    headerName: "✨ score",
  },
  {
    field: "rating",
    headerName: "stars",
    width: 150,
    type: "number",
    renderCell: (params) => <RatingContainer target={params.row} />,
  },
  {
    field: "client_comments",
    editable: true,
  },
  {
    field: "comments",
    headerName: "Notes",
    width: 180,
    renderCell: ({ id, row }) => (
      <Box>
        {row.comments?.map((comment, index) => (
          <Chip key={index} label={comment.text} size="small" sx={{ mr: 1 }} />
        ))}
      </Box>
    ),
    editable: true,
    renderEditCell: (params) => (
      <CommentsPopper id={params.id} target={params.row} />
    ),
    valueFormatter: (value) => {
      return value.map((comment) => comment.text).join("; ");
    },
  },
  {
    field: "name",
    editable: true,
  },
  {
    field: "year_founded",
    type: "string",
    editable: true,
  },
  {
    field: "contact_address",
    editable: true,
  },
  {
    field: "city",
    width: 90,
    editable: true,
  },
  {
    field: "state",
    width: 80,
    editable: true,
  },
  {
    field: "postal_code",
    width: 80,
    editable: true,
  },
  {
    field: "country",
    width: 80,
    editable: true,
  },
  {
    field: "contact_phone",
    editable: true,
  },
  {
    field: "contact_mobile",
    editable: true,
  },
  {
    field: "domain",
    width: 150,
    renderCell: ({ value }) => (
      <Link
        href={`https://www.${value}`}
        target="_blank"
        underline="hover"
        color="inherit"
      >
        {value}
      </Link>
    ),
  },

  {
    field: "gpt_description",
    headerName: "✨ description",
    width: 200,
    editable: true,
    type: "string",
    renderEditCell: (params) => <EditTextarea {...params} />,
  },
  {
    field: "employees",
    type: "number",
    width: 100,
    editable: true,
  },
  {
    field: "linkedin_employee_range",
    editable: true,
  },
  {
    field: "ownership",
    width: 100,
    editable: true,
    type: "singleSelect",
    valueOptions: ["Private", "Public", "Private Equity", "Other"],
  },
  {
    field: "was_acquired",
    headerName: "✨ acquired",
    editable: true,
  },
  {
    field: "justification",
    headerName: "✨ justification",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
  },

  {
    field: "ultimate_owner",
    width: 100,
    editable: true,
  },
  {
    field: "designation",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: ["Minority-Owned", "Woman-Owned", "Other", "None"],
  },
  {
    field: "contact_title",
    editable: true,
  },
  {
    field: "contact_name",
    editable: true,
  },
  {
    field: "contact_first_name",
    editable: true,
  },
  {
    field: "contact_last_name",
    editable: true,
  },

  {
    field: "contact_email",
    editable: true,
  },
  {
    field: "industry",
    editable: true,
  },
  {
    field: "business_type",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Manufacturer", "Distributor", "Service"],
    renderCell: ({ value }) => value,
  },
  {
    field: "products",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                sx={{ mx: 0.5 }}
                color={
                  searchData?.meta?.products
                    ?.toLowerCase()
                    .includes(val.trim().toLowerCase())
                    ? "primary"
                    : "default"
                }
              />
            ))}
      </Box>
    ),
  },
  {
    field: "services",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                sx={{ mx: 0.5 }}
                color={
                  searchData?.meta?.services
                    ?.toLowerCase()
                    .includes(val.trim().toLowerCase())
                    ? "primary"
                    : "default"
                }
              />
            ))}
      </Box>
    ),
  },
  {
    field: "end_market",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                sx={{ mx: 0.5 }}
                color={
                  searchData?.meta?.end_market
                    ?.toLowerCase()
                    .includes(val.trim().toLowerCase())
                    ? "primary"
                    : "default"
                }
              />
            ))}
      </Box>
    ),
  },
  {
    field: "revenue_estimates",
    type: "number",
    editable: true,
    valueGetter: (value) => {
      // Check if the value is undefined or null
      if (value == null) {
        return null; // or return a default value like 0
      }
      // Parse to Int if the value exists
      return parseInt(value);
    },
  },
  {
    field: "location_count",
    type: "number",
    editable: true,
  },
  {
    field: "facility_size",
    editable: true,
  },
  {
    field: "geographies",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                sx={{ mx: 0.5 }}
                color={
                  searchData?.meta?.geographies
                    ?.toLowerCase()
                    .includes(val.trim().toLowerCase())
                    ? "primary"
                    : "default"
                }
              />
            ))}
      </Box>
    ),
  },

  {
    field: "source",
    editable: true,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            .split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                color="primary"
                size="small"
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },

  {
    field: "updated",
    width: 120,
    renderCell: ({ row }) => {
      if (!row.updated) return "";
      return fromNow(row.updated);
    },
  },
  {
    field: "updated_by",
    width: 120,
  },

  {
    field: "change_date",
  },
  {
    field: "linkedin",
    headerName: "",
    editable: true,
    width: 50,
    renderCell: ({ value }) =>
      value ? (
        <IconButton href={value} target="_blank">
          <LinkedIn style={{ color: "lightgray" }} />
        </IconButton>
      ) : null,
  },
  {
    field: "headquarters",
  },
  {
    field: "Score",
    type: "number",
    headerName: "Total Score",
    width: 150,
    // Use valueGetter to dynamically calculate the score
    valueGetter: (value, row) => calculateFocusScore(row),
  },
  // {
  //   field: "_searches",
  // },
];

const createCustomColumns = (customColumns) => {
  return customColumns.map((columnName) => ({
    field: columnName,
    width: 130,
    editable: true,
    type: /focus|score/i.test(columnName) ? "number" : "string",
  }));
};

export const getCustomColumns = (searchData) => {
  return searchData?.meta?.custom_columns
    ? createCustomColumns(searchData.meta.custom_columns)
    : [];
};

export const getColumns = (searchData) => {
  return [...standardColumns(searchData), ...getCustomColumns(searchData)];
};
