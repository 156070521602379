import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2", // Light blue for better readability
    },
    secondary: {
      main: "#FF6F61",
    },
    auto: {
      main: "#04FFFF",
    },
    darkgray: {
      main: "#6B6B6B",
    },
    success: {
      main: "#4CC2A7",
    },
    error: {
      main: "#BC2B20",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
        },
        textPrimary: {
          color: "white", // This will make text of all primary buttons white
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          padding: 3,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          colorScheme: "dark",
        },
      },
    },
  },
});

export default theme;
