import {
  AccountBalance,
  CheckCircle,
  Flag,
  FlagOutlined,
  Hub,
  Inbox,
  MailOutline,
  OutboundOutlined,
  Paragliding,
  RemoveCircleOutlineOutlined,
  RemoveCircleOutlined,
  StarBorder,
  StopCircle,
  Sync,
  ThumbUp,
  VerifiedUser,
} from "@mui/icons-material";

export const DEALCLOUD_COMPANY_BASE =
  "https://genz.dealcloud.com/portal/pages/3500/reports/64997/entries/";

export const DEALCLOUD_ENGAGEMENT_BASE =
  "https://genz.dealcloud.com/portal/pages/3541/reports/3360/entries/";

export const LABEL_MAP = {
  // seller: "Seller",
  land: "Landing",
  create: "Inbox",
  similar: "Similar",
  advance: "Review",
  hold: "Hold",
  validate: "Validated",
  outbox: "Ready to Send",
  send: "Client Inbox",
  client_approve: "Client Approved",
  sync: "Synced",
  reject: "Reject",
  conflict: "Conflict",
  client_conflict: "Client Conflict",
  client_reject: "Client Reject",
  buyer: "Buyer",
};

export const ICON_MAP = {
  seller: <StarBorder />,
  land: <Paragliding />,
  create: <Inbox />,
  similar: <Hub />,
  advance: <ThumbUp />,
  hold: <StopCircle />,
  validate: <VerifiedUser />,
  outbox: <OutboundOutlined />,
  send: <MailOutline />,
  client_approve: <CheckCircle />,
  sync: <Sync />,
  reject: <RemoveCircleOutlineOutlined />,
  client_reject: <RemoveCircleOutlined />,
  conflict: <FlagOutlined />,
  client_conflict: <Flag />,
  buyer: <AccountBalance />,
};

export const FIT_OPTIONS = [
  "All",
  "Good fit",
  "Potential fit",
  "Not a fit",
  "Unsure",
];

export const SEARCH_TYPES = ["Platform", "Add-On", "Market Map", "Seller"];

export const SOURCE_OPTIONS = [
  "Client",
  "Conference",
  "DealCloud",
  "Organic Research",
  "Grata",
  "Cyndx",
  "Sourcescrub",
  "Google",
  "Idea",
  "Similar Search",
];

export const OWNERSHIP = [
  "bootstrapped",
  "investor_backed",
  "public",
  "public_subsidiary",
  "private_subsidiary",
  "private_equity",
  "private_equity_add_on",
];

export const STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const COUNTRIES = {
  // Top 30 by GDP
  USA: "United States",
  CHN: "China",
  JPN: "Japan",
  DEU: "Germany",
  GBR: "United Kingdom",
  IND: "India",
  FRA: "France",
  ITA: "Italy",
  CAN: "Canada",
  BRA: "Brazil",
  KOR: "South Korea",
  AUS: "Australia",
  MEX: "Mexico",
  ESP: "Spain",
  IDN: "Indonesia",
  TUR: "Turkey",
  NLD: "Netherlands",
  SAU: "Saudi Arabia",
  CHE: "Switzerland",
  POL: "Poland",
  SWE: "Sweden",
  BEL: "Belgium",
  IRL: "Ireland",
  ARG: "Argentina",
  NOR: "Norway",
  ISR: "Israel",
  THA: "Thailand",
  AUT: "Austria",
  ARE: "United Arab Emirates",
  TWN: "Taiwan",

  // Additional European countries not in top 30
  BGR: "Bulgaria",
  HRV: "Croatia",
  CYP: "Cyprus",
  CZE: "Czech Republic",
  DNK: "Denmark",
  EST: "Estonia",
  FIN: "Finland",
  GRC: "Greece",
  HUN: "Hungary",
  ISL: "Iceland",
  LVA: "Latvia",
  LIE: "Liechtenstein",
  LTU: "Lithuania",
  LUX: "Luxembourg",
  MLT: "Malta",
  MCO: "Monaco",
  MNE: "Montenegro",
  PRT: "Portugal",
  ROU: "Romania",
  SMR: "San Marino",
  SRB: "Serbia",
  SVK: "Slovakia",
  SVN: "Slovenia",
  VAT: "Vatican City",
};
